<template>
  <div class="rightbox">
    <div class="title titleborder">
      {{ form.lectureTitle }}
      <div class="rightreleaseTime">讲座时间：{{ form.lectureTime }}</div>
    </div>
    <div v-html="form.lectureContent"></div>
    <div>
      <div v-if="form.signInState != -1">
        <div>
          <div v-if="form.signInState == 2">
            <div class="button" v-if="form.signInState == 2">已签到</div>
          </div>
          <div v-else>
            <div class="button" v-if="form.signInState == 1">已报名</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div
            v-if="
              new Date().getTime() <
              new Date(
                new Date(form.lectureTime).getFullYear() +
                  '/' +
                  (new Date(form.lectureTime).getMonth() + 1) +
                  '/' +
                  new Date(form.lectureTime).getDate() +
                  ' ' +
                  '23:00:00'
              ).getTime() +
                1000 * 60 * 60 * 24
            "
          >
            <div class="button button1" @click="signUp(form.id)">去报名</div>
          </div>
          <div v-else>
            <div class="button">已结束</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveLectureInfoUser, getLectureInfoInfo } from "@/api/lectureHall.js";
export default {
  data() {
    return {
      form: {},
      id: "",
    };
  },
  watch: {
    $route(val) {
      if (val.name == "lecturedetial") {
        console.log(val, "val");
        this.id = this.$route.query.id;
        this.getdetal(this.id);
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getdetal(this.id);
  },
  methods: {
    // 讲座详情
    getdetal(id,type) {
      getLectureInfoInfo({ id: id,from:2 }).then((res) => {
        if (res.code == 1) {
          this.form = res.data || {};
          if(type == 2){
            this.$message({
              message: "报名成功",
              type: "success",
            });
          }
        }
      });
    },
    signUp(id) {
      let sessionId = sessionStorage.getItem("sessionId") || null;
      if (sessionId) {
        saveLectureInfoUser({ lectureId: id }).then((res) => {
          if (res.code == 1) {
            this.getdetal(this.id,2)
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "请先登录",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  margin: 0 auto;
  width: 124px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #e6e6e6;
  cursor: pointer;
  color: #fff;
  margin-top: 30px;
  border-radius: 19px;
}
.button1 {
  background-color: #1ab394 !important;
}
.rightbox {
  width: 960px;
  box-sizing: border-box;
  // padding-left: 30px;
  .title {
    width: 930px;
    // height: 106px;
    font-size: 26px;
    color: #222222;
    // line-height: 106px;
    text-align: center;
    padding-bottom: 30px;
  }
  .titleborder {
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
  }
  .rightreleaseTime {
    position: absolute;
    bottom: 10px;
    right: 0px;
    font-size: 14px;
    height: 20px;
    color: #999999;
  }
}
</style>